import React from "react"
import Layout from "../components/layout"
import DynamicComponent from "../components/dynamic-components/dynamic-component"
import { graphql } from "gatsby"
import SEO from "../components/seo"

const DynamicFrontPage = ({ data, location }) => {
  const { dynamic_page_components } = data.sanityDynamicPage
  return (
    <Layout>
      <SEO
        title={data.sanityDynamicPage.dynamic_page_title}
        url={location.pathname}
      />
      <DynamicComponent components={dynamic_page_components} />
    </Layout>
  )
}

export default DynamicFrontPage

export const query = graphql`
  query($slug: String) {
    sanityDynamicPage(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      _id
      dynamic_page_title
      dynamic_page_components {
        ... on SanityProductTeaserWRecipe {
          _key
          _type
          title
          sub_title
          recipe {
            slug {
              current
            }
            recipe_title
            recipe_image_image {
              video
              image {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
          inverted
          refs {
            _id
            product_name
            slug {
              current
            }
            _type
            product_flavor_profile {
              score {
                title
                score
              }
            }
            product_variants {
              variant_type
              variants {
                _key
                variant_info_price
                variant_info_value
              }
            }
            product_collection {
              slug {
                current
              }
            }
            product_label {
              label_title
              label_color {
                section_background
              }
            }
            product_image {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
            product_disabled
          }
        }
        ... on SanityHeroSimple {
          _type
          _key
          cta_title
          title
          collection_reference {
            featured_image {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
            slug {
              current
            }
            color {
              section_background
            }
          }
        }
        ... on SanityHeroBanner {
          _key
          _type
          hero_title
          page_title
          hero_cta {
            cta_text
            cta_link {
              ... on SanityArticle {
                _type
                slug {
                  current
                }
              }
              ... on SanityCollection {
                slug {
                  current
                }
                _type
              }
              ... on SanityProduct {
                slug {
                  current
                }
                _type
              }
              ... on SanityRecipe {
                slug {
                  current
                }
                _type
              }
            }
          }
          hero_primary_section {
            pitch
            ref {
              ... on SanityRecipe {
                article_meta_data: recipe_meta_data {
                  article_type_ref {
                    article_type_title
                  }
                }
                article_image: recipe_image_image {
                  article_image: image {
                    asset {
                      fluid(maxWidth: 1000) {
                        ...GatsbySanityImageFluid
                      }
                    }
                  }
                }
                article_title: recipe_title
                slug {
                  current
                }
                _type
              }
              ... on SanityArticle {
                article_meta_data {
                  article_type_ref {
                    article_type_title
                  }
                }
                article_image {
                  asset {
                    fluid(maxWidth: 1000) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
                article_title
                slug {
                  current
                }
                _type
              }
              ... on SanityProduct {
                _type
                slug {
                  current
                }
                product_collection {
                  slug {
                    current
                  }
                }
                product_preview_color {
                  section_background
                }
                product_name
                product_image {
                  asset {
                    fluid {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
              ... on SanityCollection {
                id
                _type
                slug {
                  current
                }
                featured_image {
                  asset {
                    fluid {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
                color {
                  section_background
                }
              }
            }
          }
          hero_secondary_section {
            pitch
            ref {
              ... on SanityRecipe {
                article_meta_data: recipe_meta_data {
                  article_type_ref {
                    article_type_title
                  }
                }
                recipe_image_image {
                  image {
                    asset {
                      fluid(maxWidth: 1000) {
                        ...GatsbySanityImageFluid
                      }
                    }
                  }
                }
                article_title: recipe_title
                slug {
                  current
                }
                _type
              }
              ... on SanityArticle {
                slug {
                  current
                }
                _type
                article_meta_data {
                  article_type_ref {
                    article_type_title
                  }
                }
                article_title
                article_image {
                  asset {
                    fluid(maxWidth: 500) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
              ... on SanityProduct {
                _type
                slug {
                  current
                }
                product_collection {
                  slug {
                    current
                  }
                }
                product_preview_color {
                  section_background
                }
                product_name
                product_image {
                  asset {
                    fluid(maxWidth: 500) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
              ... on SanityCollection {
                id
                slug {
                  current
                }
                featured_image {
                  asset {
                    fluid {
                      src
                    }
                  }
                }
              }
            }
          }
        }
        ... on SanityProductTeaser {
          _type
          _key
          cta_section {
            section_cta_cta {
              cta_link {
                ... on SanityArticle {
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityCollection {
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityDynamicPage {
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityProduct {
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityRecipe {
                  _type
                  slug {
                    current
                  }
                }
              }
              cta_text
            }
            section_cta_paragraph
            section_cta_title
          }
          refs {
            _id
            product_name
            slug {
              current
            }
            _type
            product_flavor_profile {
              score {
                title
                score
              }
            }
            product_variants {
              variant_type
              variants {
                _key
                variant_info_price
                variant_info_value
              }
            }
            product_collection {
              slug {
                current
              }
            }
            product_label {
              label_title
              label_color {
                section_background
              }
            }
            product_image {
              asset {
                fluid(maxWidth: 500) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            product_disabled
          }
        }
        ... on SanitySectionIntroText {
          _key
          _type
          section_link_text
          section_text
          section_title
          section_color {
            section_background
          }
          section_link {
            ... on SanityArticle {
              id
              slug {
                current
              }
              _type
            }
            ... on SanityProduct {
              id
              _type
              slug {
                current
              }
            }
          }
        }
        ... on SanityArticleTeaser {
          _key
          _type
          article_teaser_references {
            ... on SanityArticle {
              _id
              slug {
                current
              }
              title: article_title
              meta: article_meta_data {
                article_type_ref {
                  article_type_title
                }
              }
              _type
              image: article_image {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            ... on SanityRecipe {
              _id
              slug {
                current
              }
              _type
              title: recipe_title
              meta: recipe_meta_data {
                article_type_ref {
                  article_type_title
                }
              }
              image: recipe_image_image {
                video
                image {
                  asset {
                    fluid {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
            }
          }
          cta_section {
            section_cta_cta {
              cta_link {
                ... on SanityArticle {
                  slug {
                    current
                  }
                  _type
                }
                ... on SanityArticleType {
                  slug {
                    current
                  }
                  _type
                }
                ... on SanityCollection {
                  slug {
                    current
                  }
                  _type
                }
                ... on SanityDynamicPage {
                  slug {
                    current
                  }
                  _type
                }
                ... on SanityProduct {
                  slug {
                    current
                  }
                  _type
                }
                ... on SanityRecipe {
                  slug {
                    current
                  }
                  _type
                }
              }
              cta_text
            }
            section_cta_paragraph
            section_cta_title
          }
        }
        ... on SanityPageTitle {
          _key
          _type
          page_title_header
          page_title_intro
          topics {
            topics {
              article_type_title
              slug {
                current
              }
            }
          }
        }
        ... on SanityAllTopics {
          _key
          _type
        }
        ... on SanityCollectionTeaser {
          _key
          _type
          collection_references {
            color {
              section_background
            }
            featured_image {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
            slug {
              current
            }
            pitch
            collection_title
            _id
          }
        }
      }
    }
  }
`
